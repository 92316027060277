<template>
  <div v-if="isValidated" class="d-flex flex-grow-1 flex-column pmi-quote-form" v-bind:style="$store.getters.mainBg">
    <b-row class="pt-4 pb-4 pl-8 pr-8" align-v="center" v-if="$store.getters.hasHeader" v-bind:style="$store.getters.headerBackground">
      <b-col>
        <img
          :src="$store.getters.logoPath"
          class="h-50px align-self-center"
          alt=""
        />
      </b-col>
      <b-col class="text-center">
        <div class="h1 font-weight-bolder" v-bind:style="$store.getters.headerTitleColor">{{$store.getters.headerText}}</div>
      </b-col>
      <b-col class="text-right">
        <span v-if="$store.getters.phoneNumberText" class="h4" v-bind:style="$store.getters.headerTitleColor">Need help? Call</span><br/>
        <span class="h1" v-bind:style="$store.getters.headerTitleColor">{{$store.getters.phoneNumberText}}</span>
      </b-col>
    </b-row>
    <div>
      <div class="bg" v-bind:style="$store.getters.elementColourBlockBg"></div>
      <b-row class="mt-4">
        <b-col xs="12" sm="1" md="1" lg="1" xl="1"></b-col>
        <b-col xs="12" sm="10" md="10" lg="10" xl="10">
          <div class="p-2">
            <b-nav class="border-0" tabs justified v-model="tabIndex">
              <b-nav-item
                v-bind:style="$store.getters.tabsBox"
                class="quote-tab" 
                :to="{ name: 'PMIQuoteForm' }" 
                :active="this.$route.name == 'PMIQuoteForm'"
                >
                <div class="tab-icon" v-bind:style="$store.getters.tabsText"><b-icon icon="people-fill" font-scale="1" class="mr-4"></b-icon></div>
                <div class="d-flex flex-row justify-content-center flex-grow-1" v-bind:style="$store.getters.tabsText">
                  <div class="align-self-center tab-text">Your Details</div>
                </div>
              </b-nav-item>
              <b-nav-item 
                v-bind:style="$store.getters.tabsBox"
                class="quote-tab" 
                :to="{ name: 'PMIQuoteResult' }" 
                v-bind:disabled="hasResults" 
                :active="this.$route.name == 'PMIQuoteResult'"
                >
                <div class="tab-icon" v-bind:style="$store.getters.tabsText"><b-icon icon="grid1x2-fill" font-scale="1" class="mr-4"></b-icon></div>
                <div class="d-flex flex-row justify-content-center flex-grow-1" v-bind:style="$store.getters.tabsText">
                  <div class="align-self-center tab-text">Results</div>
                </div>
              </b-nav-item>
              <b-nav-item
                v-if="!$store.getters.externalApplication"
                v-bind:style="$store.getters.tabsBox"
                class="quote-tab" 
                :to="{ name: 'PMIQuoteApplication'}" 
                v-bind:disabled="hasProductSelected" 
                :active="this.$route.name == 'PMIQuoteApplication'"
                >
                <div class="tab-icon" v-bind:style="$store.getters.tabsText"><b-icon icon="bookmark-fill" font-scale="1" class="mr-4"></b-icon></div>
                <div class="d-flex flex-row justify-content-center flex-grow-1" v-bind:style="$store.getters.tabsText">
                  <div class="align-self-center tab-text">Application</div>
                </div>
              </b-nav-item>
              <b-nav-item
                v-if="!$store.getters.externalApplication"
                v-bind:style="$store.getters.tabsBox"
                class="quote-tab" 
                :to="{ name: 'PMIQuoteApplication'}" 
                v-bind:disabled="!hasApplicationDetails" 
                :active="this.$route.name == 'PMIQuotePayment'"
                >
                 <div class="tab-icon" v-bind:style="$store.getters.tabsText"><b-icon icon="credit-card-fill" font-scale="1" class="mr-4"></b-icon></div>
                <div class="d-flex flex-row justify-content-center flex-grow-1" v-bind:style="$store.getters.tabsText">
                  <div class="align-self-center tab-text">Apply Now</div>
                </div>
              </b-nav-item>
            </b-nav>
            <router-view class="plugin-container"/>
          </div>
        </b-col>
        <b-col xs="12" sm="1" md="1" lg="1" xl="1"></b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { VALIDATE_FORM } from "@/core/services/store/auth.module";

export default {
  name: "pmi-quote",
  data() {
    return {
      tabIndex: 1
    }
  },
  computed: {
    isValidated: {
      get(){
        return this.$store.state.auth.isValidated;
      }
    },
    hasResults: {
      get() {
        return this.$store.state.pmi.quoteResults.length == 0;
      }
    },
    hasProductSelected: {
      get() {
        return Object.keys(this.$store.state.pmi.selectedProduct).length == 0;
      }
    },
    hasCorrectInfo: {
      get() {
        return this.$store.state.pmi.quoteApplication.isInformationCorrect;
      }
    },
    hasApplicationDetails: {
      get() {
        return this.$store.state.pmi.quoteApplication.hasApplicationDetails;
      }
    },
    displayMode: {
      get() {
        return this.$route.query.display;
      }
    }
  },
  watch: {
    '$route.query.colorSettingId': function(){
      this.$store.dispatch("getColorSettingById", this.$route.query.colorSettingId)
      .then(() => {})
    },
    '$route.query.reloadHash': function(){
      this.$store.dispatch("getColorSettingById", this.$route.query.colorSettingId)
      .then(() => {})
    },
    '$route.query.appKey': function(){
      console.log(this.$route.query.appKey);
    }
  },
  created: function(){
    window.addEventListener("resize", this.screenResize);
    this.ready(function(){
      setTimeout(function(){
        window.dispatchEvent(new Event('resize'));
      }, 1000);
    });

    var colorSettingId = this.$route.query.colorSettingId;
     
    if(this.$route.query.displayMode){
      this.$store.commit("updateDisplayMode", this.$route.query.displayMode);
      this.initializePlugin({});
      this.$store.dispatch("getColorSettingById", colorSettingId)
      .then(() => {})
      .catch(() => {});
    }
  },
  methods: {
    screenResize(){
      this.$resizeFrame();
    },
    initializePlugin(credentials){
      console.log(credentials);
      this.$store.dispatch(VALIDATE_FORM, credentials)
      .then(data => {
        var pluginMessage = { messageType: "validated" };
        window.parent.postMessage(JSON.stringify(pluginMessage),"*");

        this.$store.dispatch("getColorSettingById", data.colorSettingId)
        .then(() => {})
        .catch(() => {});
      })
      .catch(() => {});
    },
    ready: function ready(callback){
      if (document.readyState!='loading') callback();
      else if (document.addEventListener) document.addEventListener('DOMContentLoaded', callback);
      else document.attachEvent('onreadystatechange', function(){
          if (document.readyState=='complete') callback();
      });
    }
  },
  mounted: function(){
    const _this = this;
    window.addEventListener("message", function(res){
      console.log(res);
      var messageData = {};
      try{
        messageData = JSON.parse(res.data);
      } catch(err){
        console.log(err);
      }
      
      if(messageData.hasOwnProperty("messageType") && messageData.messageType === "validate"){
        _this.initializePlugin(messageData.payload);
      }

      if(messageData.hasOwnProperty("messageType") && messageData.messageType === "reloadPreview"){
        _this.$store.dispatch("getColorSettingById", messageData.payload.colorSettingId).then(() => {})
      }

      if(messageData.hasOwnProperty("messageType") && messageData.messageType === "loadQuote"){
        _this.$resetFrame();
        _this.$store.commit("updateLoadingStatus", true);
        _this.$store.commit("updateViewMode", false);
        _this.$store.commit("updateEditMode", false);

        _this.$store.dispatch("getPreviousQuote", messageData.payload.quoteRef)
          .then(() => {
            _this.$router.push({ name: 'PMIQuoteResult' });
            _this.$store.commit("updateLoadingStatus", false);
          })
          .catch(() => {
            _this.$store.commit("updateLoadingStatus", false);
          });
      }
    }, false);
  },
  destroyed: function(){
    window.removeEventListener("resize", this.screenResize);
  }
}
</script>

<style lang="scss">
.quote-tab {
  font-size: 16px;
  font-weight: 800;
  position: relative;

  a {
    padding: 15px !important;
    text-align: center;
  }

  .tab-icon {
    position: absolute;
    top: 5px;
    left: 5px;
  }
}

.plugin-container {
  border-left: solid 1px #E4E6EF;
  border-right: solid 1px #E4E6EF;
  border-bottom: solid 1px #E4E6EF;
  background-color: #FFFFFF;
  padding: 1.5rem !important;
}

.bg {
  background-color: #077EE2;
  height: 150px;
  width: 100%;
  position: absolute;
}

.quote-tab {
  .nav-link {
    height: 100%;

    &:not(.active){  
      background-color: #505050;
      border-bottom: 0;
      opacity: 0.3;

      .tab-text, .tab-icon {
        color: #FFFFFF !important;  
      }
    }
  }
}

@media (max-width: 576px) {
  .plugin-container {
    padding: 0.75rem !important;
  }
}
</style>
